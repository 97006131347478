const triggerTarget = Array.from(document.querySelectorAll('.js-scroll-trigger'));

if (triggerTarget) {
    const callback = (triggers) => {
        triggers.forEach(trigger => {
            if (trigger.isIntersecting) {
                const navItems = Array.from(document.querySelectorAll('.c-sideNav__item'));
                navItems.forEach((item) => {
                    item.classList.remove('is-current');
                });
                let href = '#' + trigger.target.id;
                let targetElement = document.querySelector(`[href="${href}"]`).parentNode;
                targetElement.classList.add('is-current');
            }
        });
    }

    const observer = new IntersectionObserver(callback, {
        root: null,
        rootMargin: '0% 0px -70% 0px',
        // threshold: 0.5
    });

    triggerTarget.forEach(target => {
        observer.observe(target);
    })
}