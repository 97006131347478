// .outerHeight();

if (window.matchMedia('(max-width: 768px)').matches) {
  var hei = $(".l-header-bottom").outerHeight();
  var wH = $(window).height();
  var index = wH - hei;
  // console.log(hei);
  // console.log(wH);
  // console.log(index);

  $('.l-header-function').css('min-height', index);
}