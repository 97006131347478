import Accordion from '../../plugins/accordion.js';
const elements = document.querySelectorAll(".js-accordion");

for (const element of elements) {
  let accordion2 = new Accordion(element, {
    multipleOpen: true,
    onOpen: test,
    onClose: fuga
  });
}


let accordionPrice = new Accordion(".js-accordion2", {
  defaultOpenPanels: [0],
  // duration:'2s',
  onOpen: priceOpen,
  onClose:priceClose
});

function test(trigger, panel){
  trigger.style.color = '#000'
}
function fuga(trigger, panel){
  trigger.style.color = '#000'
}

function priceOpen(trigger, panel){
  
}
function priceClose(trigger, panel){
  
}

// accordion.destroy()
// accordion.init()
// #9fc351


// 20240321 森下 スマホヘッダー用 アコーディオン
document.addEventListener('DOMContentLoaded', () => {
  const accordions = document.querySelectorAll('.js-header-accrodion');

  accordions.forEach(accordion => {
    const trigger = accordion.querySelector('.js-header-accrodion__trigger');
    trigger.addEventListener('click', () => {
      const content = accordion.querySelector('.js-header-accrodion__element');
      const isOpen = accordion.dataset.open === 'true';

      // 他のアコーディオンを閉じ、その高さをリセットする
      accordions.forEach(otherAccordion => {
        if (otherAccordion !== accordion) {
          otherAccordion.dataset.open = 'false';
          otherAccordion.querySelector('.js-header-accrodion__element').style.maxHeight = '0';
        }
      });

      // 現在のアコーディオンの開閉状態を切り替え
      accordion.dataset.open = String(!isOpen);

      // 高さを動的に調整
      content.style.maxHeight = !isOpen ? `${content.scrollHeight}px` : '0';
    });
  });
});