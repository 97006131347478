// import Simplemodal from '../../plugins/simplemodal.js';

// const fullScreenMenu = new Simplemodal({
//   trigger : "data-fullScreenMenu-trigger",
//   clickOutSideClose : true
// })

import Simplemodal from '../../plugins/simplemodal.js';

const fullScreenMenu = new Simplemodal({
  trigger: "data-fullScreenMenu-trigger",
  clickOutSideClose: true,
  backFixed: true,
  onOpen: openAction,
  onClose: closeAction
});

function openAction() {
  document.querySelector("header").classList.add("fullscreen-modal-open");
}
function closeAction() {
  document.querySelector("header").classList.remove("fullscreen-modal-open");
}

document.addEventListener("click", e => {
  if (e.target.closest(".js-ankerLink")) {
    fullScreenMenu.closeAllModal();
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const heroSection = document.querySelector('.l-hero');

  // `l-hero__bgDark`クラスが`heroSection`に含まれている場合のみ、以下の処理を実行
  if (heroSection && heroSection.classList.contains('l-hero__bgDark')) {
    const fullScreenMenu = document.getElementById('js-fullScreenMenu-content');
    const logoImgBlack = document.querySelector('.logo-img-black');
    const logoImgWhite = document.querySelector('.logo-img-white');

    // `aria-hidden`の状態を監視するMutationObserverを設定
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'aria-hidden') {
          const isAriaHidden = fullScreenMenu.getAttribute('aria-hidden') === 'true';
          logoImgBlack.style.display = isAriaHidden ? 'none' : 'flex';
          logoImgWhite.style.display = isAriaHidden ? 'flex' : 'none';
        }
      });
    });

    // observerの設定
    observer.observe(fullScreenMenu, { attributes: true });
  }
});