function fadeAnime(){
  $('.fadeUpTrigger').each(function(){ //fadeInTriggerというクラス名が
    var elemPos = $(this).offset().top-50;//要素より、50px上の
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll >= elemPos - windowHeight){
      $(this).addClass('fadeUp');// 画面内に入ったらfadeInというクラス名を追記
    }else{
      $(this).removeClass('fadeUp');// 画面外に出たらfadeInというクラス名を外す
    }
  });
}

// 画面をスクロールをしたら動く場合の記述
$(window).scroll(function (){
	fadeAnime();/* アニメーション用の関数を呼ぶ*/
});// ここまで画面をスクロールをしたら動く場合の記述

// 画面が読み込まれたらすぐに動く場合の記述
$(window).on('load', function(){
	fadeAnime();/* アニメーション用の関数を呼ぶ*/
});// ここまで画面が読み込まれたらすぐに動く場合の記述
