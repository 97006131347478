import Swiper from "swiper";

window.addEventListener('load', function () {

  // let sliderOfficeHeroThumb = new Swiper(".officeGuide-hero-slider--thumb", {
  //   loop: true,
  //   spaceBetween: 20,
  //   slidesPerView: 3,
  // });

  let sliderOfficeHero = new Swiper(".officeGuide-hero-slider", {
    loop: true,
    speed: 7000,
    slidesPerView: 1,
    spaceBetween: 30,
    autoplay: {
      delay: 1000,
    },
    // thumbs: {
    //   swiper: sliderOfficeHeroThumb,
    // },
    breakpoints: {
      480: {
        slidesPerView: 'auto',
        centeredslides: true,
        autoplay: {
          delay: 1000,
        },
        autoplay: {
          delay: 0,
        },
      }
    }
  });


  if( document.querySelector('.swiper-wrapper') ){
    let target = document.querySelector('.swiper-wrapper');
    console.log(target)
    target.style.opacity = 1;
  }


  let topHeroSwiper = new Swiper(".js-top-hero-swiper", {
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 30,
    speed: 1500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    effect: "fade",
    fade: {
      crossFade: true,
    },
  });



});